import { LoadingProcess, UseStart } from '../types';
import { storageZoneServicePlugin } from '@/features/storageZone';
import {
  pushNotificationServicePlugin,
  PushProviderPermissionError,
} from '@/features/push-notification';
import { DataSyncScopes, dataSyncServicePlugin } from '@/features/data-sync';
import { ordersServicePlugin } from '@/features/orders';
import { oauthServicePlugin } from '@/features/oauth';
import { User, userServicePlugin } from '@/features/user';
import { ref } from 'vue';
import { errorPlugin } from '@/features/core/errors';
import {
  NoMerchantForUserError,
  PushPermissionError,
} from '@/features/login/errors';
import { authServicePlugin } from '@/features/core/auth';
import { configurationServicePlugin } from '@/features/configuration';
import { appServiceWorkerServicePlugin } from '@/features/service-worker';
import { BackgroundSyncTags } from '@/features/data-background-sync/types';
import { tourCrashServicePlugin } from '@/features/tour-crash';
import {
  orderMetadataServicePlugin,
  useOrderMetadata,
} from '@/features/orderMetadata';
import { toursServicePlugin } from '@/features/tours';
import { retrySynchronisationsPlugin } from '@/features/retry-synchronisation';
import { loggerServicePlugin } from '@/features/core/logger';
import { deviceServicePlugin } from '@/features/device-info';
import { surveyServiceClientPlugin } from '@/features/survey';

export function useStart(): UseStart {
  const loadingData = ref({
    configuration: LoadingProcess.NotStarted,
    data: LoadingProcess.NotStarted,
    sync: LoadingProcess.NotStarted,
    order: LoadingProcess.NotStarted,
    tour: LoadingProcess.NotStarted,
  });

  const fetchAndStoreStorageZones = async () => {
    await storageZoneServicePlugin.get().fetchAllStorageZones();
    await dataSyncServicePlugin
      .get()
      .setDataSync(DataSyncScopes.StorageZoneSync, new Date());
  };

  const configurationLoading = async () => {
    loadingData.value.configuration = LoadingProcess.Loading;
    errorPlugin.get().setVisibleErrorPopup(true);
    await configurationServicePlugin.get().fetchAllConfigurations();
    errorPlugin.get().setVisibleErrorPopup(false);
    loadingData.value.configuration = LoadingProcess.Loaded;
  };

  const pushNotificationSubscribe = async (user: User) => {
    if (!userServicePlugin.get().isDriverRole(user.roles)) {
      const pushNotificationService = pushNotificationServicePlugin.get();
      pushNotificationService.init();
      await deviceServicePlugin.get().updateAndSync();
    }
  };

  const { checkAndSetOrdersMetadata } = useOrderMetadata();
  const orderLoading = async (withBackgroundFullSync = true) => {
    loadingData.value.order = LoadingProcess.Loading;

    const orders = await ordersServicePlugin.get().fetchAllOrders();
    await orderMetadataServicePlugin.get().clearAll();
    await checkAndSetOrdersMetadata(orders);

    if (withBackgroundFullSync) {
      await appServiceWorkerServicePlugin
        .get()
        .notifySync(BackgroundSyncTags.NormalBackgroundSync);

      await appServiceWorkerServicePlugin
        .get()
        .registerPeriodicSync(BackgroundSyncTags.PeriodicBackgroundSync);
    }
  };

  const tourLoading = async () => {
    loadingData.value.tour = LoadingProcess.Loading;

    await toursServicePlugin.get().fetchAllTours();

    loadingData.value.tour = LoadingProcess.Loaded;
  };

  const storageZoneLoading = async () => {
    await fetchAndStoreStorageZones();

    loadingData.value.data = LoadingProcess.Loaded;
  };

  const loadData = async () => {
    try {
      await configurationLoading();
      await surveyServiceClientPlugin.get().clearAll();
      loadingData.value.data = LoadingProcess.Loading;
      const user = await userServicePlugin.get().setUserByMerchant();
      if (user.merchant.reference) {
        if (userServicePlugin.get().isDriverRole(user.roles)) {
          const pickingOrderDependencyActive = await configurationServicePlugin
            .get()
            .getFeatureOption(
              'driverApp',
              'pickingOrdersDependencyActive',
              'boolean',
            );

          loadingData.value.data = LoadingProcess.Loaded;
          if (!pickingOrderDependencyActive) {
            await orderLoading(false);
          }
          loadingData.value.order = LoadingProcess.Loaded;

          await tourLoading();

          await tourCrashServicePlugin.get().init();
          return '/tours';
        } else {
          errorPlugin.get().setVisibleErrorPopup(true);
          await storageZoneLoading();
          loadingData.value.sync = LoadingProcess.Loading;
          await pushNotificationSubscribe(user);
          loadingData.value.sync = LoadingProcess.Loaded;

          await orderLoading();
          errorPlugin.get().setVisibleErrorPopup(false);
          return '/';
        }
      } else {
        await authServicePlugin.get().removeUserToken();
        errorPlugin.get().setVisibleErrorPopup(false);
        errorPlugin.get().handle(new NoMerchantForUserError());
        return;
      }
    } catch (error) {
      loggerServicePlugin.get().error(error as Error);
      if (error instanceof PushProviderPermissionError) {
        errorPlugin.get().setVisibleErrorPopup(false);
        errorPlugin.get().handle(new PushPermissionError());

        await oauthServicePlugin.get().logout('empowerId');
        await userServicePlugin.get().clearUser();
      } else {
        retrySynchronisationsPlugin.get().retry();
        return;
      }
    }
  };

  return {
    fetchAndStoreStorageZones,
    loadingData,
    loadData,
  };
}
